import { PageProps, graphql } from 'gatsby';
import { DatoCmsSeoField, DatoCmsTemplatePage } from 'graphqlTypes';

import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';

import type { FC } from 'react';

interface PageDataType {
  pageData: DatoCmsTemplatePage;
}

const Page: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, sections },
  },
  location,
  pageContext,
}) => (
  <Layout
    slug={location.pathname}
    seo={seo as DatoCmsSeoField}
    domain={(pageContext as any)?.domain}
    lang={(pageContext as any)?.language}
  >
    {sections &&
      sections.map(
        (section, index) =>
          section && (
            <LayoutSection
              key={section.id}
              isLastSection={index === sections.length - 1}
              {...section}
              domain={(pageContext as any)?.domain}
              lang={(pageContext as any)?.language}
              nextHasBackground={!!sections?.[index + 1]?.backgroundColor}
            />
          ),
      )}
  </Layout>
);
export default Page;

export const pageQuery = graphql`
  query templatePageQuery($slug: String!, $domain: String!, $language: String!) {
    pageData: datoCmsTemplatePage(slug: { eq: $slug }, domain: { eq: $domain }, language: { eq: $language }) {
      ...datoCmsTemplatePage
    }
  }
`;
